
import { defineComponent, ref, provide } from 'vue';

import TheHeader from './components/layout/TheHeader.vue';
import TheContents from './components/layout/TheContents.vue';
import MainContents from './components/layout/MainContents.vue';
import SideContents from './components/layout/SideContents.vue';

import Login from './components/page/Login.vue';

import PageTemplate from './components/template/PageTemplate.vue';
import ComponentDocs from './components/template/ComponentDocs.vue';
import NoticeModalTemplate from './components/template/NoticeModalTemplate.vue';

import { SearchForm, SearchCell } from './components/organisms/searchForm';
import { EntryForm, EntryHdg, EntryRow, EntryTtl } from './components/organisms/entryForm/';
import SmartForm from './components/organisms/SmartForm.vue';

import {
  GridTable,
  GridCaption,
  GridHead,
  GridBody,
  GridRow,
  GridCell,
  GridSort,
} from './components/molecules/gridTable';
import {
  FacilityInspectionGridTable,
  FacilityInspectionGridCaption,
  FacilityInspectionGridHead,
  FacilityInspectionGridBody,
  FacilityInspectionGridRow,
  FacilityInspectionGridCell,
  FacilityInspectionGridSort,
} from './components/-system additional-/facilityInspectionGridTable';
import {
  FacilityItemsGridTable,
  FacilityItemsGridCaption,
  FacilityItemsGridHead,
  FacilityItemsGridBody,
  FacilityItemsGridRow,
  FacilityItemsGridCell,
  FacilityItemsGridSort,
} from './components/-system additional-/facilityItemsGridTable';
import { LineTable, LineHead, LineBody, LineRow, LineCell } from './components/molecules/LineTable';
import Formfield from './components/molecules/FormField.vue';
import Dropdown from './components/molecules/Dropdown.vue';
import Pagination from './components/molecules/Pagination.vue';
import Clipboard from './components/molecules/Clipboard.vue';
import Highlight from './components/molecules/Highlight.vue';

import Navi from './components/atoms/DefaultLink.vue';
import Btn from './components/atoms/DefaultButton.vue';
import Info from './components/atoms/Information.vue';
import Flex from './components/atoms/Flex.vue';
import Badge from './components/atoms/Badge.vue';
import Modal from './components/atoms/Modal.vue';

import {
  Inputfirld,
  Inputarea,
  Range,
  File,
  Datepicker,
  Colorpicker,
  Radio,
  Checkbox,
  Toggle,
  Selectbox,
  ZipCode,
  MultiFile,
  MultiCheckbox,
  MultiRadio,
  Wysiwyg,
  Markdown,
  ModalInput,
} from './components/form';

import {DownloadForm, DownloadCell} from './components/-system additional-/downloadForm/'

export default defineComponent({
  components: {
    TheHeader,
    TheContents,
    MainContents,
    SideContents,
    Login,
    PageTemplate,
    NoticeModalTemplate,
    ComponentDocs,
    SearchForm,
    SearchCell,
    EntryForm,
    EntryHdg,
    EntryRow,
    EntryTtl,
    SmartForm,
    Formfield,
    GridTable,
    GridCaption,
    GridHead,
    GridBody,
    GridRow,
    GridCell,
    GridSort,
    LineTable,
    LineHead,
    LineBody,
    LineRow,
    LineCell,
    Dropdown,
    Pagination,
    Clipboard,
    Highlight,
    Navi,
    Btn,
    Info,
    Flex,
    Badge,
    Modal,
    Inputfirld,
    Inputarea,
    Range,
    File,
    Datepicker,
    Colorpicker,
    Radio,
    Checkbox,
    Toggle,
    Selectbox,
    ZipCode,
    MultiFile,
    MultiCheckbox,
    MultiRadio,
    Wysiwyg,
    Markdown,
    FacilityInspectionGridTable,
    FacilityInspectionGridCaption,
    FacilityInspectionGridHead,
    FacilityInspectionGridBody,
    FacilityInspectionGridRow,
    FacilityInspectionGridCell,
    FacilityInspectionGridSort,
    FacilityItemsGridTable,
    FacilityItemsGridCaption,
    FacilityItemsGridHead,
    FacilityItemsGridBody,
    FacilityItemsGridRow,
    FacilityItemsGridCell,
    FacilityItemsGridSort,
    ModalInput,
    DownloadForm,
    DownloadCell,
  },
  setup() {
    /* ローカル用データ */
    const local = ref<object>({});
    provide('setLocal', (data: object) => {
      local.value = { ...local.value, ...data };
    });

    /* スマホメニュー展開 */
    let menuState = ref(false);
    provide('menuState', menuState);
    provide('menuToggle', () => {
      menuState.value = !menuState.value;
      setTimeout(() => {
        if (menuState.value) {
          document.addEventListener('click', onMenuOuterClick);
        }
      });
    });

    /* ヘッダーナビ */
    let headNavi = ref();
    provide('headNavi', headNavi);
    provide('setHeadNavi', (data: object) => {
      headNavi.value = data;
    });

    // @method onOuterClick アウタークリック
    const onMenuOuterClick = (event: Event) => {
      const target = <HTMLElement>event.target;
      if (!target.closest('.l-side') && !target.closest('.l-header')) {
        menuState.value = false;
        document.removeEventListener('click', onMenuOuterClick);
      }
    };

    /* フォーム用データ */
    const params = ref<any>({});

    return {
      local,
      params,
    };
  },
});
