<template>
  <div class="entry-row"><slot /></div>
</template>

<style lang="scss">
@import './src/scss/variable';
@import './src/scss/mixin';
.entry-row {
  display: flex;
  gap: 10px;
  align-items: baseline;
  padding: 30px 20px;
  + .entry-row {
    border-top: 1px solid $border;
  }
  .entry-row {
    gap: 5px;
    padding: 15px 10px;
  }
  + .entry-hdg {
    margin-top: 20px;
  }

  @include mq(sp) {
    flex-direction: column;
    padding: 25px 0;
  }
}
</style>
