<template>
  <div class="s-component_docs">
    <slot></slot>
  </div>
</template>

<style lang="scss">
@import '/src/scss/variable';
@import './src/scss/mixin';
.s-component_docs {
  margin-bottom: 100px;
  .docs_text {
    margin: 20px 0;
    font-size: 14px;
    p {
      line-height: 2;
      + p {
        margin-top: 0.5em;
      }
    }
    code {
      padding: 1px 5px 2px;
      font-family: $fontBase;
      font-size: 12px;
      font-style: italic;
      white-space: nowrap;
      background: $lightGray;
      border-radius: 3px;
    }
  }

  .docs_example {
    padding: 30px;
    background: #eee;
    border: 1px solid #bbb;
    border-radius: 5px;
    .preview_area {
      display: flex;
      gap: 30px;
      align-items: center;
      justify-content: center;
      padding: 30px;
      background: #fff;
      .area_fill {
        width: 100%;
      }
      + .code {
        margin-top: 20px;
      }
    }
    .code {
      + .code {
        &::before {
          display: block;
          font-family: 'Line Awesome Free';
          font-size: 26px;
          font-weight: 900;
          text-align: center;
          content: '\f103';
        }
      }
    }
  }

  .docs_table {
    margin-bottom: 40px;
    code {
      padding: 1px 5px 2px;
      font-family: $fontBase;
      font-size: 12px;
      font-style: italic;
      white-space: nowrap;
      background: $lightGray;
      border-radius: 3px;
    }
    pre {
      display: inline-block;
      padding: 1px 5px 2px;
      font-family: $fontBase;
      font-size: 12px;
      font-style: italic;
      background: $lightGray;
      border-radius: 3px;
    }
    th {
      white-space: nowrap;
    }
    sup {
      font-size: 10px;
      font-weight: 400;
      color: rgb(200 0 0);
    }
    em {
      padding: 0 5px;
      margin-right: 4px;
      font-size: 11px;
      font-style: normal;
      color: #fff;
      background: rgb(200 0 0);
      border-radius: 3px;
    }
    + .docs_text {
      margin-top: -20px;
    }
    .c-info {
      margin-top: 10px;
    }
  }

  .docs_demo {
    border: 1px solid $border;
    border-radius: 5px;
    .preview_area {
      position: sticky;
      top: $headerHeight;
      z-index: 10;
      display: flex;
      justify-content: center;
      padding: 30px;
      background-image: repeating-linear-gradient(
        135deg,
        rgb(238 238 238 / 100%) 2% 4%,
        rgb(255 255 255 / 100%) 4% 6%
      );
      border-bottom: 1px solid $border;
      border-radius: 5px 5px 0 0;
      box-shadow: 0 0 5px rgb(0 0 0 / 20%);
      .area_fill {
        width: 100%;
        &.center {
          text-align: center;
        }
      }
    }
    .demo_area {
      padding: 20px;
      margin-top: 20px;

      .demo_row {
        display: flex;
        + .demo_row {
          padding-top: 20px;
          margin-top: 20px;
          border-top: 1px solid $border;
        }
      }
      .demo_col {
        display: flex;
        flex: 1;
        gap: 20px;
        align-items: center;
      }
      .demo_label {
        width: 100px;
        font-size: 14px;
        font-weight: 600;
        color: $secondaryColor;
        text-align: right;
        white-space: nowrap;
      }
    }
    a.is_active {
      font-weight: 900;
    }
    button.is_active {
      border: 3px solid #f00;
    }
  }

  .docs_notes {
    + .docs_notes {
      padding-top: 25px;
      margin-top: 25px;
      border-top: 1px dashed $border;
    }

    .notes_ttl {
      padding-left: 5px;
      margin-bottom: 15px;
      overflow: hidden;
      font-size: 16px;
      border-left: 4px solid $border;

      @include lineHeight(1.8);
    }
    .notes_body {
      font-size: 14px;
      line-height: 1.8;
      p {
        + p {
          margin-top: 1.5em;
        }
      }
      code {
        padding: 1px 5px 2px;
        font-family: $fontBase;
        font-size: 12px;
        font-style: italic;
        white-space: nowrap;
        background: $lightGray;
        border-radius: 3px;
      }
      & + .m-highlight {
        padding-top: 25px;
      }
    }
  }

  .docs_sample {
    display: flex;
    border: 1px solid $border;
    border-radius: 5px;
    &.is_col {
      flex-direction: column;
      .codeview_area,
      .preview_area {
        width: 100%;
      }
    }
    + .docs_sample {
      margin-top: 10px;
    }
    .codeview_area,
    .preview_area {
      width: 50%;
    }
    .codeview_area {
      position: relative;
      display: flex;
      align-items: center;
      background: #1e1e22;
      .m-highlight {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: auto;
        .highlight_body {
          position: static;
          width: 100%;
        }
        pre code.hljs {
          overflow: visible;
        }
      }
    }
    .preview_area {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
      background-image: repeating-linear-gradient(
        135deg,
        rgb(238 238 238 / 50%) 2% 4%,
        rgb(255 255 255 / 50%) 4% 6%
      );
      .area_fill {
        width: 100%;
      }
    }
  }

  @include mq(sp) {
    margin-bottom: 30px;
    .docs_text {
      margin: 10px 0;
      font-size: 12px;
    }

    .docs_example {
      padding: 15px;
      .preview_area {
        padding: 15px;
      }
    }

    .docs_demo {
      .preview_area {
        padding: 15px;
      }
      .demo_area {
        padding: 20px;
        margin-top: 20px;

        .demo_row {
          display: block;
        }
        .demo_col {
          gap: 30px;
          + .demo_col {
            padding-top: 20px;
            margin-top: 20px;
            border-top: 1px solid $border;
          }
        }
        .demo_label {
          width: 80px;
          padding-left: 10px;
          font-size: 12px;
        }
      }
    }

    .docs_notes {
      + .docs_notes {
        padding-top: 15px;
        margin-top: 15px;
      }

      .notes_ttl {
        font-size: 14px;
      }
      .notes_body {
        font-size: 12px;
        line-height: 1.8;
        p {
          + p {
            margin-top: 1em;
          }
        }
      }
    }

    .docs_sample {
      display: block;
      + .docs_sample {
        margin-top: 30px;
      }
      .codeview_area,
      .preview_area {
        width: auto;
      }
      .preview_area {
        padding: 15px;
      }
    }
  }
}
</style>
