<template>
  <main class="l-main">
    <slot />
  </main>
</template>

<style lang="scss">
@import './src/scss/variable';
@import './src/scss/mixin';
.l-main {
  position: relative;
}

.c-hdg {
  margin-bottom: 20px;
  font-weight: 500;
}
h1.c-hdg {
  font-size: 28px;
  font-weight: 800;
}
h2.c-hdg {
  font-size: 24px;
  font-weight: 600;
}
h3.c-hdg {
  font-size: 18px;
  font-weight: 600;
}
h4.c-hdg {
  font-size: 15px;
  font-weight: 600;
}
h5.c-hdg {
  font-size: 13px;
}
h6.c-hdg {
  font-size: 11px;
}

.c-section {
  + .c-section {
    margin-top: 70px;
  }
}
</style>
