<template>
  <div class="sort_nav">
    <slot />
  </div>
</template>

<style lang="scss">
@import './src/scss/variable';
@import './src/scss/mixin';
.m-grid_table {
  .sort_nav {
    position: absolute;
    top: 0;
    right: 15px;
    display: inline-flex;
    flex-direction: column;
    gap: 3px;
    justify-content: center;
    height: 100%;
    a {
      position: relative;
      &::before {
        position: absolute;
        display: block;
        content: ' ';
        border: 4px solid transparent;
        transition: border-color 200ms ease-out;
      }
      &:nth-of-type(1) {
        &::before {
          bottom: 0;
          border-bottom: 6px solid $defaultText;
        }
        &.is_active {
          &::before {
            border-bottom-color: $primaryLight;
          }
        }

        @include hover {
          &::before {
            border-bottom-color: $primaryLight;
          }
        }
      }
      &:nth-of-type(2) {
        &::before {
          top: 0;
          border-top: 6px solid $defaultText;
        }
        &.is_active {
          &::before {
            border-top-color: $primaryLight;
          }
        }

        @include hover {
          &::before {
            border-top-color: $primaryLight;
          }
        }
      }
    }
  }
}
</style>
