<template>
  <caption>
    <span class="grid_table_caption_sticky"><slot /></span>
  </caption>
</template>

<style lang="scss">
@import './src/scss/variable';
@import './src/scss/mixin';
.m-grid_table {
  caption {
    color: #fff;
    text-align: left;
    background: $primaryColor;
    .grid_table_caption_sticky {
      position: sticky;
      left: 0;
      display: inline-block;
      padding: 10px 15px 15px;
    }
  }
}
</style>
