/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios';

export default function Apical(option: {
  url: string;
  method: string;
  data?: FormData | object;
  params?: object;
  headers?: object;
  alert?: (message: string, note?: string) => void;
  onSuccess: (data: any) => void;
  onError?: (data?: object) => void;
}) {
  axios({
    method: option.method,
    url: option.url,
    data: option.data,
    params: option.params,
    headers: option.headers,
  })
    .then((response) => {
      option.onSuccess(response.data);
    })
    .catch(
      (error: {
        response: {
          data: {
            error: string;
          };
        };
      }) => {
        const err = error as AxiosError;
        if (err.response && err.response.data) {
          const message = error.response.data.error || 'エラーが発生しました。';
          const note = `Fetch : ${err.response.status} (${err.response.statusText})`;
          if (option.alert) {
            option.alert(message, note);
          } else {
            alert(`${message}\n${note}`);
          }
          option.onError && option.onError(err.response.data);
        } else {
          alert('エラーが発生しました');
        }
      }
    );
}
